import React, { useEffect } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import { AppThunkDispatch, RootState } from '../../../redux'
import { siteUpgradeMonitoring } from '../../../redux/upgradeMonitoring/slice'
import { SiteMonitoring } from '../../../models/upgradeMonitoring'
import { Table } from '../../../components/Table'
import { ProgressBar } from '../../../components/ProgressBar'
import { GreenSuccess, RedError } from '../../../common/colorConstants'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetSites: (...args: Parameters<typeof siteUpgradeMonitoring>) => dispatch(siteUpgradeMonitoring(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    company: state.company,
    sites: state.upgradeMonitoring.sites,
    requestState: state.upgradeMonitoring.siteUpgradeMonitoringState
  }
}

function getFormattedDate(date: string): string {
  return new Date(date).toLocaleString(undefined, {
    hour12: false, year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'
  })
}

function ScheduledTime(props: { scheduleType: 'Download' | 'Upgrade', date: string }): JSX.Element {
  return (
    <Typography>
      {`${props.scheduleType}: ${getFormattedDate(props.date)}`}
    </Typography>
  )
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>

type UpgradeMonitoringTableProps = DispatchProps & StateProps

const checkIcon = <CheckCircleIcon sx={{ color: GreenSuccess }} />// color="success" />
const cancelIcon = <CancelIcon sx={{ color: RedError }} />// color="error" />

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Site ID',
    flex: 1
  },
  {
    field: 'siteName',
    headerName: 'Site Name',
    flex: 1
  },
  {
    field: 'timezone',
    headerName: 'Timezone',
    flex: 1
  },
  {
    field: 'schedule',
    headerName: 'Schedule',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <div>
        {
          params.row.schedule?.download
          && params.row.schedule.download !== ''
          && <ScheduledTime scheduleType="Download" date={params.row.schedule.download} />
        }
        {
          params.row.schedule?.upgrade
          && params.row.schedule.upgrade !== ''
          && <ScheduledTime scheduleType="Upgrade" date={params.row.schedule.upgrade} />
        }
      </div>
    )
  },
  {
    field: 'package',
    headerName: 'Package',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => params.row.package != null && (params.row.package ? checkIcon : cancelIcon)
  },
  {
    field: 'progress',
    headerName: 'Progress',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => params.row.progress && <ProgressBar value={params.row.progress} />
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 1
  },
  {
    field: 'checkpoint',
    headerName: 'Checkpoint',
    flex: 1
  }
]

export function UpgradeMonitoringTable(
  {
    company,
    user,
    sites,
    requestState,
    dispatchGetSites
  }: UpgradeMonitoringTableProps
): JSX.Element {
  useEffect(() => {
    if (
      user.dataCenter
      && user.userId
      && user.sessionId
    ) {
      const { userId, sessionId, dataCenter } = user
      dispatchGetSites({
        userId,
        sessionId,
        dataCenter,
        companyid: company.selectedCompany?.id
      })
    }
  }, [company.selectedCompany?.id])

  const rows = sites.map((d: SiteMonitoring) => ({
    id: d.siteId,
    siteName: d.siteName,
    timezone: d.timezone,
    schedule: { download: d.scheduledDownload, upgrade: d.scheduledUpgrade },
    package: d.isPackageJsonValid,
    progress: d.progress,
    state: d.agentState,
    checkpoint: d.checkpointState
  }))

  return (
    <Table
      width="100%"
      rows={requestState === 'pending' ? [] : rows}
      columns={columns}
      rowsToShow={5}
      rowsToShowOptions={[5, 10, 20]}
      toolbar
      loading={requestState === 'pending'}
      disableSelectionOnClick
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeMonitoringTable)
