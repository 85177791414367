import React from 'react'
import { connect } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro'
import { SiteDeviceData } from '../../../data/siteReadinessData'
import { Table } from '../../../components/Table'
import { RootState } from '../../../redux'
import { GreenSuccess, RedError } from '../../../common/colorConstants'

function mapStateToProps(state: RootState) {
  return {
    devices: state.siteReadiness.devices
  }
}

export interface OwnProps {
  onRowClick: (site: GridRowParams) => void
}

type StateProps = ReturnType<typeof mapStateToProps>
type SiteReadinessDeviceTableProps = StateProps & OwnProps

export function SiteReadinessDevicesTable(
  {
    devices,
    onRowClick
  }: Readonly<SiteReadinessDeviceTableProps>
): JSX.Element {
  const checkIcon = <CheckCircleIcon sx={{ color: GreenSuccess }} />
  const cancelIcon = <CancelIcon sx={{ color: RedError }} />

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Device ID',
      hide: true
    },
    {
      field: 'deviceName',
      headerName: 'Device Name',
      flex: 1
    },
    {
      field: 'isPrereqReady',
      headerName: 'HW/SW Prereq',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (params.row.isPrereqReady ? checkIcon : cancelIcon)
    },
    {
      field: 'isUpgradeReady',
      headerName: 'Upgrade Ready',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (params.row.isUpgradeReady ? checkIcon : cancelIcon)
    }
  ]

  const rows = devices.map((d: SiteDeviceData) => ({
    id: d.deviceId,
    deviceName: d.deviceName,
    isPrereqReady: d.isPrereqReady,
    isUpgradeReady: d.isUpgradeReady
  }))

  return (
    <Table
      width="100%"
      rows={rows}
      columns={columns}
      rowsToShow={5}
      rowsToShowOptions={[5, 10, 20]}
      toolbar
      disableSelectionOnClick
      onRowClick={(params) => onRowClick(params)}
    />
  )
}

export default connect(mapStateToProps)(SiteReadinessDevicesTable)
