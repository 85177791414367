import React, { useEffect, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { AppThunkDispatch, RootState } from '../../redux'
import { BreadcrumbNavigation, BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import { breadcrumbDispatchHelper } from '../../components/BreadcrumbNavigation/breadcrumbNavigationHelpers'
import { setUpgradeMonitoringBreadcrumbs } from '../../redux/upgradeMonitoring/slice'
import UpgradeMonitoringTable from './components/upgradeMonitoringTable'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetUpgradeMonitoringBreadcrumbs: (...args: Parameters<typeof setUpgradeMonitoringBreadcrumbs>) => dispatch(setUpgradeMonitoringBreadcrumbs(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    breadcrumbs: state.upgradeMonitoring.breadcrumbs,
    user: state.user
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

type UpgradeMonitoringProps = StateProps & DispatchProps

export function UpgradeMonitoring({
  breadcrumbs,
  dispatchSetUpgradeMonitoringBreadcrumbs,
  user
}: UpgradeMonitoringProps): JSX.Element {
  const [breadcrumbDepth] = useState<number>(1)

  const currentSegment: BreadcrumbSegment = {
    name: 'Monitoring',
    path: '/monitoring',
    order: breadcrumbDepth,
    testId: 'Monitoring'
  }

  useEffect(() => {
    breadcrumbDispatchHelper(
      breadcrumbs,
      currentSegment,
      (segments) => { dispatchSetUpgradeMonitoringBreadcrumbs(segments) }
    )
  }, [breadcrumbDepth])

  if (!user.isAdmin) return <h1>Unauthorized Access</h1>

  return (
    <Stack
      alignItems="baseline"
      spacing={4}
    >
      <BreadcrumbNavigation breadcrumbs={breadcrumbs} />
      <Typography variant="h4" data-testid="UpgradeMonitoringHeader">
        Monitoring
      </Typography>
      <Typography variant="body1">
        Click any site to view detail/devices
      </Typography>
      <Typography>
        If you do not see your site on this grid, please verify Upgrade Agent is installed on all devices and is reporting data through Pulse Streaming (BOH) and Edge Agent (FOH)
      </Typography>
      <UpgradeMonitoringTable />
    </Stack>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeMonitoring)
