import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SiteMonitoring } from '../../models/upgradeMonitoring'
import { RequestState } from '../common'
import { buildAuthenticatedUser } from '../../common/authenticatedUser'
import { getSiteUpgradeMonitoring } from '../../data/upgradeMonitoring'
import { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'

export const siteUpgradeMonitoring = createAsyncThunk(
  'upgradeMonitoring/siteUpgradeMonitoring',
  async (args: {
    userId: string
    sessionId: string
    dataCenter: string
    companyid: string | undefined
  }): Promise<SiteMonitoring[]> => {
    const {
      userId,
      sessionId,
      dataCenter,
      companyid
    } = args
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    if (companyid == null) {
      throw new Error('Site readiness requires a company id')
    }
    return getSiteUpgradeMonitoring(authUser, companyid)
  }
)

export interface UpgradeMonitoringState {
  siteUpgradeMonitoringState: RequestState
  sites: SiteMonitoring[]
  breadcrumbs: BreadcrumbSegment[]
}

const initialState: UpgradeMonitoringState = {
  siteUpgradeMonitoringState: 'idle',
  sites: [],
  breadcrumbs: []
}

export const upgradeMonitoringSlice = createSlice({
  name: 'upgradeMonitoring',
  initialState,
  reducers: {
    setSiteUpgradeMonitoringState: (
      state,
      action: PayloadAction<RequestState>
    ) => {
      state.siteUpgradeMonitoringState = action.payload
    },
    setUpgradeMonitoringBreadcrumbs: (state, action: PayloadAction<BreadcrumbSegment[]>) => {
      state.breadcrumbs = [...action.payload].sort((a, b) => a.order - b.order)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(siteUpgradeMonitoring.pending, (state) => {
      state.siteUpgradeMonitoringState = 'pending'
    })
    builder.addCase(siteUpgradeMonitoring.rejected, (state) => {
      state.siteUpgradeMonitoringState = 'rejected'
    })
    builder.addCase(siteUpgradeMonitoring.fulfilled, (state, action) => {
      state.sites = action.payload
      state.siteUpgradeMonitoringState = 'fulfilled'
    })
  }
})

export default upgradeMonitoringSlice.reducer
export const {
  setSiteUpgradeMonitoringState,
  setUpgradeMonitoringBreadcrumbs
} = upgradeMonitoringSlice.actions
