import { get } from '../common/upgradesApiClient'
import { AuthenticatedUser } from '../common/authenticatedUser'
import { SiteMonitoring } from '../models/upgradeMonitoring'
import { AppError } from '../common/result'

export async function getSiteUpgradeMonitoring(authUser: AuthenticatedUser, companyId: string): Promise<SiteMonitoring[]> {
  const response = await get<SiteMonitoring[]>(
    {
      route: `monitoring/companysites/${companyId}`,
      user: authUser
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}
