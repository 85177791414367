import React from 'react'
import { LinearProgress, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DarkVoyixPurple, LightVoyixPurple, RedError } from '../../common/colorConstants'

export interface ProgressBarProps {
  /**
   * The value for progression.
   */
  value: number

  /**
   * Indicates whether the progress bar should render as an error.
   */
  error?: boolean
}

function getBarColor(progress: number): string {
  return progress >= 100 ? DarkVoyixPurple : LightVoyixPurple
}

export function ProgressBar({
  value,
  error
}: Readonly<ProgressBarProps>) {
  const barColor: string = error ? RedError : getBarColor(value)
  return (
    <Box sx={{ display: 'flex', aligItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1, color: barColor }}>
        <LinearProgress variant="determinate" color="inherit" value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

ProgressBar.defaultProps = {
  error: false
}
